import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => (
  <>
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand href="#home">
        {' '}
        { siteTitle }
      </Navbar.Brand>
    </Navbar>
  </>
)

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
